import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import Masonry from 'react-masonry-component';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';

//images
import gallery1 from './../../images/blog/grid/pic1.jpg';
import gallery2 from './../../images/blog/grid/pic2.jpg';
import gallery3 from './../../images/blog/grid/pic3.jpg';
import gallery4 from './../../images/blog/grid/pic4.jpg';
import gallery5 from './../../images/blog/grid/pic5.jpg';
import gallery6 from './../../images/blog/grid/pic6.jpg';
import gallery7 from './../../images/blog/grid/pic7.jpg';
import gallery8 from './../../images/blog/grid/pic8.jpg';
import gallery9 from './../../images/blog/grid/pic9.jpg';
import gallery10 from './../../images/blog/grid/pic10.jpg';
import gallery11 from './../../images/blog/grid/pic11.jpg';
import gallery12 from './../../images/blog/grid/pic12.jpg';
import gallery13 from './../../images/blog/grid/pic13.jpg';
import gallery14 from './../../images/blog/grid/pic14.jpg';
import gallery15 from './../../images/blog/grid/pic15.jpg';
import gallery16 from './../../images/blog/grid/pic16.jpg';
import gallery17 from './../../images/blog/grid/pic17.jpg';
import gallery18 from './../../images/blog/grid/pic18.jpg';
import gallery19 from './../../images/blog/grid/pic19.jpg';
import gallery20 from './../../images/blog/grid/pic20.jpg';
import gallery21 from './../../images/blog/grid/pic21.jpg';
import gallery22 from './../../images/blog/grid/pic22.jpg';
import gallery23 from './../../images/blog/grid/pic23.jpg';
import gallery24 from './../../images/blog/grid/pic24.jpg';
import gallery25 from './../../images/blog/grid/pic25.jpg';
import gallery26 from './../../images/blog/grid/pic26.jpg';
import gallery27 from './../../images/blog/grid/pic27.jpg';
import gallery28 from './../../images/blog/grid/pic28.jpg';
import gallery29 from './../../images/blog/grid/pic29.jpg';
import gallery30 from './../../images/blog/grid/pic30.jpg';
import gallery31 from './../../images/blog/grid/pic31.jpg';
import gallery32 from './../../images/blog/grid/pic32.jpg';
import gallery33 from './../../images/blog/grid/pic33.jpg';
import gallery34 from './../../images/blog/grid/pic34.jpg';
import gallery35 from './../../images/blog/grid/pic35.jpg';
import gallery36 from './../../images/blog/grid/pic36.jpg';
import gallery37 from './../../images/blog/grid/pic37.jpg';
import gallery38 from './../../images/blog/grid/pic38.jpg';
import gallery39 from './../../images/blog/grid/pic39.jpg';
import gallery40 from './../../images/blog/grid/pic40.jpg';
import gallery41 from './../../images/blog/grid/pic41.jpg';
import gallery42 from './../../images/blog/grid/pic42.jpg';
import gallery43 from './../../images/blog/grid/pic43.jpg';
import gallery44 from './../../images/blog/grid/pic44.jpg';
import gallery45 from './../../images/blog/grid/pic45.jpg';
import gallery46 from './../../images/blog/grid/pic46.jpg';
import gallery47 from './../../images/blog/grid/pic47.jpg';
import gallery48 from './../../images/blog/grid/pic48.jpg';
import gallery49 from './../../images/blog/grid/pic49.jpg';
import gallery50 from './../../images/blog/grid/pic50.jpg';
import gallery51 from './../../images/blog/grid/pic51.jpg';
import gallery52 from './../../images/blog/grid/pic52.jpg';
import gallery53 from './../../images/blog/grid/pic53.jpg';
import gallery54 from './../../images/blog/grid/pic54.jpg';
import gallery55 from './../../images/blog/grid/pic55.jpg';
import gallery56 from './../../images/blog/grid/pic56.jpg';
import gallery57 from './../../images/blog/grid/pic57.jpg';
import gallery58 from './../../images/blog/grid/pic58.jpg';
import gallery59 from './../../images/blog/grid/pic59.jpg';
import gallery60 from './../../images/blog/grid/pic60.jpg';
import gallery61 from './../../images/blog/grid/pic61.jpg';
import gallery62 from './../../images/blog/grid/pic62.jpg';
import gallery63 from './../../images/blog/grid/pic62.jpg';

const galleryBlog = [
	{ images: gallery1 }, { images: gallery2 }, { images: gallery3 },
	{ images: gallery4 }, { images: gallery5 }, { images: gallery6 },
	{ images: gallery7 }, { images: gallery8 }, { images: gallery9 },
	{ images: gallery10 }, { images: gallery11 }, { images: gallery12 },
	{ images: gallery13 }, { images: gallery14 }, { images: gallery15 },
	{ images: gallery16 }, { images: gallery17 }, { images: gallery18 },
	{ images: gallery19 }, { images: gallery20 }, { images: gallery21 },
	{ images: gallery22 }, { images: gallery23 }, { images: gallery24 },
	{ images: gallery25 }, { images: gallery26 }, { images: gallery27 },
	{ images: gallery28 }, { images: gallery29 }, { images: gallery30 },
	{ images: gallery31 }, { images: gallery32 }, { images: gallery33 },
	{ images: gallery34 }, { images: gallery35 }, { images: gallery36 },
	{ images: gallery37 }, { images: gallery38 }, { images: gallery39 },
	{ images: gallery40 }, { images: gallery41 }, { images: gallery42 },
	{ images: gallery43 }, { images: gallery44 }, { images: gallery45 },
	{ images: gallery46 }, { images: gallery47 }, { images: gallery48 },
	{ images: gallery49 }, { images: gallery50 }, { images: gallery51 },
	{ images: gallery52 }, { images: gallery53 }, { images: gallery54 },
	{ images: gallery55 }, { images: gallery56 }, { images: gallery57 },
	{ images: gallery58 }, { images: gallery59 }, { images: gallery60 },
	{ images: gallery61 }, { images: gallery62 }, { images: gallery63 },
	
];
// Masonry section
const masonryOptions = {
	transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
		<Link onClick={() => openLightbox(props.imageToOpen)} className="check-km" >
			<i className="fa fa-search icon-bx-xs"></i>
		</Link>
	)
}

class Gallery extends Component {
	render() {
		return (
			<Fragment>
				<Header />
				<div className="page-content">
					<PageTitle motherMenu="Annual Day" activeMenu="Annual Day" />
					<div className="content-block">
						<div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>Annual Day</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'}
													options={masonryOptions}
													disableImagesLoaded={false}
													updateOnEachImageLoad={false}
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((item, index) => (
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 wow zoomIn" key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1 ">
																	<img src={item.images} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon">
																			<Iconimage />
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>
											</ul>
										</div>
									</SRLWrapper>
								</SimpleReactLightbox>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}
export default Gallery;