import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom'; 
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import AccordionBlog from '../Element/AccordionBlog';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import VideoPopup from '../Element/VideoPopup';
//Images
import bnr1 from './../../images/line2.png';
import bnr2 from './../../images/background/bg5.jpg';
import bnr4 from './../../images/line2.png';
import bgimg2 from './../../images/background/bg1.jpg';
import about from './../../images/about/about-1.jpg';
import about1 from './../../images/about/about-2.jpg';
import bnr3 from './../../images/background/bg6.jpg';

import gallery1 from './../../images/blog/grid/pic1.jpg';
import gallery2 from './../../images/blog/grid/pic23.jpg';
import gallery3 from './../../images/blog/grid/pic9.jpg';
import gallery4 from './../../images/blog/grid/pic24.jpg';
import gallery5 from './../../images/blog/grid/pic58.jpg';
import gallery6 from './../../images/blog/grid/pic20.jpg';

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

//Light Gallery Icon
const Iconimage = props => {
	const { openLightbox } = useLightbox()
  return (
    <Link   onClick={() => openLightbox(props.imageToOpen)} className="" >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  )
}
const galleryBlog = [
	{image: gallery1},	
	{image: gallery2},	
	{image: gallery3},	
	{image: gallery4},	
	{image: gallery5},	
	{image: gallery6},	
];

function BackgroundBlog(){
	return(
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"  style={{visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn"}}>
						<div className="section-head text-center">
							<div className="video-play">
								{/* <VideoPopup /> */}
							</div>
							<h2>Let Your Kids Have an Amazing<br/>Time at the School</h2>
							{/* <Link to={"./contact-us"} className="btn btn-md radius-xl">Read More</Link> */}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
function EducationBanner() {
	return (
		<>
			<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bgimg2 + ")" }}>
				<div className="about-overlay-box"></div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="section-head">
								<h2 className="head-title text-yellow">Education from birth<br />begins with us</h2>
								<p className="text-white">Giffi Kidz believe in nurturing the young enthusiastic minds to develop a passion towards learning hence following the curriculum based on SMART method of pedagogy.</p>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
					</div>
				</div>
			</div>
		</>
	)
}

class AboutUs1 extends Component{
	componentDidMount(){
		var splitBox = document.querySelectorAll('.split-box');
       
		var fSB = [].slice.call(splitBox);
		
		fSB.forEach(el => el.classList.add('split-active'));
	}
	render(){
		return(
			<Fragment>
				<Header />	
				 <div className="page-content">
					<PageTitle  motherMenu="About Us"  activeMenu="About Us" />
					<div className="content-block">
						<div className="section-full bg-white content-inner-2 about-area" style={{backgroundImage:"url("+ bnr1 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat",backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-12 col-sm-12 m-b15">
										<div className="section-head">
											<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>About School</h2>
											<p>
												An upcoming chain of pre schools emerged as one of the most popular pre-school in the southern part of Bengaluru. A school which takes the effort of understanding the students and then tailor make the curriculum keeping the child’s need in mind.
												Giffi Kidz believed in taking the concept of preschool to a completely different level where the children are taught to be self- dependent and become strong physically, emotionally as well as socially.
												The mission of Giffi Kidz is to nurture a society where the entire school fraternity works towards providing a compassionate, congenial and optimistic environment so as to enhance and foster the physical, emotional, cognitive, creative and social development of every individual child through play based learning and exploration.
												<p></p>
												Understanding the need to build a scientific temperament amongst the children; well planned activities are devised in order to provide every child with an equal opportunity to satisfy their quest for understanding.
											</p>
										</div>
										{/* <AccordionBlog /> */}
									</div>
									<div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about} alt=""/>
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-sm-12 m-t20 teacher-content">
										<div className="split-box">
											<div className="about-media">
												<img src={about1} alt="" />
											</div>
										</div>
									</div>

									<div className="col-lg-6 col-md-12 col-sm-12 m-t20">
										<div className="section-head">
											<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>About the Founder</h2>
											<p>
												<h5 >Ms.Pushpa Chhabria </h5>
												<p></p>
												She comes with a background of a decade's experience in the education industry. <br />
												<p></p>
												She started her career as an assistant teacher. She has headed five preschools as a franchise head for a renowned preschool chain in Bangalore. <br />
												<p></p>
												She has also worked for a main stream school as a startup team and comes with a lot of passion and enthusiasm towards children and parent counseling. <br />
												<p></p>
												Ms Pushpa Chhabria, who comes with an experience of more than a decade in the field of schooling designed an array of activities pertaining to every concept of providing a state - of – art infrastructure to fulfil the children’s requirements and also our focus is to create a conducive learning atmosphere, keeping in pace with the best practice in the industry.
											</p>
										</div>
										{/* <AccordionBlog /> */}
									</div>

								</div>
							</div>
						</div>
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bnr3 + ")"}}>
							<BackgroundBlog />
						</div>
						{/*  Portfolio  */}
						<div className="section-full content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>Gallery</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<SimpleReactLightbox>
									<SRLWrapper>
										<div className="clearfix" id="lightgallery">
											<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery">
												<Masonry
													className={'my-gallery-class'} 
													options={masonryOptions} 
													disableImagesLoaded={false} 
													updateOnEachImageLoad={false} 
													imagesLoadedOptions={imagesLoadedOptions} // default {}
												>
													{galleryBlog.map((data,index)=>(
														<li className="web design card-container col-lg-4 col-md-6 col-sm-6 "  key={index}>
															<div className="dlab-box frame-box m-b30">
																<div className="dlab-thum dlab-img-overlay1"> 
																	<img src={data.image} alt="" />
																	<div className="overlay-bx">
																		<div className="overlay-icon"> 
																			<Iconimage />
																		</div>
																	</div>
																</div>
															</div>
														</li>
													))}
												</Masonry>		
											</ul>
										</div>
									</SRLWrapper>	
								</SimpleReactLightbox>	
							</div>
						</div>
						
						<EducationBanner />
						{/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials about center</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
					</div>
				</div>	
				<Footer />	
			</Fragment>
		)
	}
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;