import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
//images
import bnr1 from "./../../images/line2.png";
import clsimg1 from "./../../images/gallery/pic13.jpg";
import clsimg2 from "./../../images/gallery/pic16.jpg";
import clsimg3 from "./../../images/gallery/pic17.jpg";
import clsimg4 from "./../../images/gallery/pic18.jpg";
import clsimg5 from "./../../images/gallery/pic26.jpg";

const classesBlog = [
  { images: clsimg1, title: "Play Group", age: "2-2.11" },
  { images: clsimg2, title: "Nursery", age: "3-3.11" },
  { images: clsimg3, title: "LKG", age: "4-4.11" },
  { images: clsimg4, title: "UKG", age: "5-5.11" },
  // { images: clsimg5, title: 'Grade I', age: " 6-6.11", },
  // { images: clsimg2, title: 'Grade II', age: "7-7.11 ", },
  { images: clsimg2, title: "DayCare", age: "0.6-12" },
];

class Classes extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Classes" activeMenu="Classes" />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row sp40">
                  {classesBlog.map((data, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                      <div className="class-item">
                        {/* <div className="class-media"> 
													<img src={data.images} alt=""/>
													<p>
														<span>Class Time:</span> 
														09:00 AM - 12:30 PM
													</p>
													{nursery && playgroup class time 09:00 AM to 12:30 PM}
												</div> */}
                        <div className="class-media">
                          <img src={data.images} alt="" />
                          <p>
                            <span>Class Time:</span>
                            {data.title === "Play Group" ||
                            data.title === "Nursery"
                              ? "09:00 AM - 12:30 PM"
                              : "08:30 AM - 12:00 PM"}
                          </p>
                        </div>
                        <div className="class-info">
                          <h4 style={{ color: "#ed5ab0" }}>{data.title}</h4>
                          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit nulla felis ipsum.</p> */}
                          <ul className="schedule">
                            <li className="bg-blue class-size">
                              <span>Class Size</span> <span>15 - 20</span>{" "}
                            </li>
                            <li></li>
                            <li className="bg-green years-old">
                              <span>Years Old</span> <span>{data.age}</span>{" "}
                            </li>
                            {/* <li className="bg-orange tution"><span>Tution Fee</span> <span>$1500</span> </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default Classes;
