import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import BannerSlider from '../Element/BannerSlider';
import FromSlider from '../Element/FromSlider';
import TestiMonialSlider from '../Element/TestiMonialSlider';
import TeacherSlider from '../Element/TeacherSlider';
import GallerySlider from '../Element/GallerySlider';

import icon1 from './../../images/icon/icon1.jpg';
import icon2 from './../../images/icon/icon2.jpg';
import icon3 from './../../images/icon/icon3.jpg';
import icon4 from './../../images/icon/icon4.jpg';
import bgimg1 from './../../images/line.png';
import bgimg3 from './../../images/line2.png';
import bnr3 from './../../images/background/bg6.jpg';

const iconBlog = [
	{ image: icon1 , title1: 'To Think Creatively', title2: 'and Create' },	
	{ image: icon2 , title1: 'To Feel Fine and to', title2:'Understand Emotions' },	
	{ image: icon3 , title1: 'To be Independent', title2: 'and Active' },	
	{ image: icon4 , title1: 'To Apply', title2:'Knowledge in Life' },	
];
const iconBlog2 = [
	{ icon:  <i className="flaticon-rattle text-blue" />, title: 'Infants', },	
	{ icon:  <i className="flaticon-bricks text-green" />, title: 'I myself', },	
	{ icon:  <i className="flaticon-puzzle text-orange" />, title: 'Goodie',  },	
];


class Index1 extends Component{
	render(){
		return(
			<Fragment>
				<Header />
				<div className="page-content bg-white">
					{ /* Slider Banner */ }
						<BannerSlider />
					{ /* Slider Banner */ }
					<div className="content-block">
						{/*  About Us */}
							<div className="section-full bg-white content-inner-1 text-center">
								<div className="container">
									<div className="section-head">
									<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>Welcome to Giffi Kidz</h2>
										<p>Fill your child's childhood with the joy of learning!</p>
									</div>
									<div className="row">
										{iconBlog.map((data, index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
												<div className="icon-bx-wraper sr-iconbox m-b20">
													<div className="icon-lg m-b20">
														<Link to={"#"} className="icon-cell"><img src={data.image} alt=""/></Link>
													</div>
													<div className="icon-content">
														<h6 className="dlab-tilte">{data.title1}<br/>{data.title2}</h6>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						{/*  About Us End*/}
						<div className="section-full bg-white content-inner-2 about-box" style={{backgroundImage:"url(" + bgimg1 +")",  backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-12">
										<div className="section-head">
											<h2 className="head-title text-secondry text-center" style={{ color: "#87c91f" }}>Our Vision</h2>
											<p>
												The founders wanted to give a redefinition of the term ‘Pre schooling’, The ultimate aim is to provide quality education at an affordable  price, and not burning a hole in the parents’ pocket. Giffi Kidz believe in nurturing the young enthusiastic minds to develop a passion towards learning  following the curriculum based on SMART method of pedagogy.
											</p>

										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12">
										<div className="section-head">

											<h2 className="head-title text-secondry text-center" style={{ color:"#87c91f"}}>Our Mission</h2>
											<p>
												The mission of Giffi Kidz is to nurture a society where the entire school fraternity works towards providing a compassionate, congenial and optimistic environment so as to enhance and foster the physical, emotional, cognitive, creative and social development of every individual child through play based learning and exploration.
											</p>
											{/* <Link to={"/contactus"} className="btn btn-md kids-btn radius-xl">Learn more</Link> */}
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="section-full bg-white content-inner-2 about-content bg-img-fix" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
							<BackgroundBlog2 />
						</div>
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>Gallery</h2>
									{/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
								</div>
								<GallerySlider />
							</div>
						</div>
						{/* <div className="section-full bg-white content-inner-1" style={{backgroundImage:"url("+ bgimg3 +")", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">About the Teachers</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<TeacherSlider />
							</div>
						</div> */}
						<div className="section-full bg-white content-inner-1">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry" style={{ color: "#87c91f" }}>Testimonials</h2>
									{/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
								</div>
								<TestiMonialSlider />
							</div>
						</div>
						{/* <div className="section-full bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">From the Blog</h2>
									<p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p>
								</div>
								<FromSlider />
							</div>
						</div> */}
					</div>
				</div>
				<Footer />
			</Fragment>
		)
	}
}

function BackgroundBlog2() {
	return (
		<>
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-12  about-text" style={{ visibility: "visible", animationDuration: "2s", animationDelay: "0.2s", animationName: "fadeIn" }}>
						<div className="section-head text-center">
							<h4 className="text-white">Join Our New Session</h4>
							<h2>Call To Enrol Your Child <br /><span className="text-yellow">+91 7406469991</span></h2>
							<Link to={"./contact-us"} className="btn btn-md radius-xl">Connect us</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export { BackgroundBlog2 };
export default Index1;