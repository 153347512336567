import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";

import bnr1 from "./../../images/line2.png";
// import team1 from './../../images/team/pic1.jpg';
import team2 from "./../../images/team/New/Swathi.png";
import team3 from "./../../images/team/New/Latha.png";
import team4 from "./../../images/team/New/Muthumalathi.png";
import team5 from "./../../images/team/New/Pramodini.png";
import team6 from "./../../images/team/New/Vinutha.png";
import team7 from "./../../images/team/New/Bhuvaneshwari.png";
import team8 from "./../../images/team/New/Hemalatha.png";
import team9 from "./../../images/team/New/Vedashree.png";
import team10 from "./../../images/team/New/Lakshmi.png";
import team11 from "./../../images/team/New/Soniya.png";
import team12 from "./../../images/team/New/Shalini.png";
import team13 from "./../../images/team/New/Thanuja.png";
import team14 from "./../../images/team/New/Dhananjaya.png";
// import team15 from "./../../images/team/team12.jpg";

//Light Gallery on icon click
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link onClick={() => openLightbox(props.imageToOpen)} className="check-km">
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

const teamBlog = [
  { image: team2 },
  { image: team3 },
  { image: team4 },
  { image: team5 },
  { image: team6 },
  { image: team7 },
  { image: team8 },
  { image: team9 },
  { image: team10 },
  { image: team11 },
  { image: team12 },
  { image: team13 },
  { image: team14 },
  // { image: team15 },
];

class Teachers extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Teachers" activeMenu="Teachers" />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="section-head text-center">
                  <h2
                    className="head-title text-secondry"
                    style={{ color: "#87c91f" }}
                  >
                    About the Teachers
                  </h2>
                  <p>
                    We have an excellent teacher to child ratio at our Preschool
                    to ensure that each child receives the attention he or she
                    needs.
                  </p>
                </div>
                <div className="row">
                  {teamBlog.map((data, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-6" key={index}>
                      <div className="dlab-box box-frame1 team-box m-b40">
                        <div className="dlab-thum">
                          <Link to={"/teachers-details"}>
                            <img src={data.image} alt="" />
                          </Link>
                          {/* <div className="overlay-bx">
                                                        <h5 className="team-title"><Link to={"/teachers-details"}>John Doe</Link></h5>
                                                        <span className="team-info">Teacher</span>
                                                        <ul className="list-inline">
                                                            <li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
                                                            <li><Link to={"#"}><i className="fa fa-google-plus"></i></Link></li>
                                                            <li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
                                                            <li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
                                                            <li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
                                                        </ul>
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Teachers;
