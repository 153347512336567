import React,{Component} from 'react';
import Slider from "react-slick";
import grid1 from './../../images/testimonials/pic2.jpg';


const latestBlog = [
	{ image: grid1, parentName: "Sunil Kumar", desc: "It is a very good preschool to start with. All the staffs are very friendly and the teaching method is also very nice. There will be lot of activities through which they teach the kids. My kid love to go to school everyday.i recommend this school to others as well." },
	{ image: grid1, parentName: "Shriti Surendranath", desc: "A place where every child would be nurtured as a responsible human. A place where u can let ur child be and u can be free from worries. My son learnt a lot in the very short span he went. Leaving a year old child with someone who is a stranger to you in this world of selfishness would attract more looks..why are u working, cant u stay back at home, he is just a year, he cant even talk.. Giffi just helped me get thru all this in a giffy. At an economical pricing with great service is what Giffi strives at. Lastly, Giffi thy name is trust, trust thy name is Giffi. Thank you Pushpa and team for helping me in the most needed time." },
	{ image: grid1, parentName: "Sreekumar S", desc: "It's a very promising new playschool/daycare/kindergarten which has come up in JP Nagar 7th phase. My daughter goes here and so far it's been a great experience. The head coordinator Pushpa makes sure that it's an engaging and fun experience for both the kids as well as the parents. Good stuff.. way to go.. best wishes." },
	{ image: grid1, parentName: "Preeti Aggarwal", desc: "A place where learning is fun ... a very calm environment with beautiful atmosphere and and every one from the teachers to the helpers are kind hearted and very responsible a good and a safe place for children to start learning and making them feel more innovative ☺️" },
]
class TestiMonialSlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<div className="sprite-nav">
					<Slider className="client-carousel owl-carousel owl-theme  " ref={c => (this.slider = c)} {...settings}>
						{latestBlog.map((item, index) => (
							<div className="item" key={index}>
								<div className="client-box">
									<div className="testimonial-detail clearfix">
										<div className="testimonial-pic radius">
											<img src={item.image} width="100" height="100" alt="" />
										</div>
										<h5 className="testimonial-name m-t0 m-b5">{item.parentName}</h5> 
										<span>Parent</span> 
									</div>
									<div className="testimonial-text">
										<p>{item.desc}</p>
									</div>
								</div>	
							</div>
						))}	
					</Slider>		
					<div className="owl-nav" style={{ textAlign: "center" }}>
						<div className=" owl-prev " onClick={this.previous}/>
						<div className="owl-next " onClick={this.next}/>
					</div>
				</div>	
			</>	
		)
	}
}
export default TestiMonialSlider;